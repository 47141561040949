import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our FREE New Years Day workout will be today at 10:30.  Partner
workout so bring a friend!  All other class times and open gym times are
cancelled.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`“2020”`}</em></strong></p>
    <p>{`2 Rounds for time. Each partner must do a 20,20 set before switching
out.`}</p>
    <p>{`20-Calorie Row, 20-Burpees Over Rower`}</p>
    <p>{`20-Calorie Bike, 20-V Ups`}</p>
    <p>{`20-Double Unders, 20-Ball Slams (30/20`}{`#`}{`)`}</p>
    <p>{`20-Wall Balls, 20-Ring Rows (20/14`}{`#`}{`)`}</p>
    <p>{`20-GHD Situps, 20-Jump Squats`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Thursday’s schedule is back to normal.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our next free workout at 9:00 & 10:00am so bring a
friend and start the new year off right!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      